<script setup lang="ts">
defineProps<{
  block?: boolean;
}>();

const { currentPalette } = useTheme();
</script>

<template>
  <div
    :class="[
      'app-skeleton animate-pulse',
      currentPalette.bg,
      {
        'app-skeleton--block block': block,
        'app-skeleton--inline-block inline-block': !block,
      },
    ]"
    v-bind="$attrs"
    aria-hidden="true"
  ></div>
</template>
